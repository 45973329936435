import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/Layout";
import { Container, Title, Body } from "../common/styled-components";
import Helmet from "react-helmet";

const About = ({ data }) => {
  const PAGE_TITLE = get(data, "allContentfulAboutPage.nodes[0].pageTitle");
  const PAGE_DESCRIPTION = get(
    data,
    "allContentfulAboutPage.nodes[0].pageDescription"
  );
  const IMAGE = get(data, "allContentfulAboutPage.nodes[0].image.file.url");
  const HEADING = get(data, "allContentfulAboutPage.nodes[0].heading");
  const TAG_LINE = get(data, "allContentfulAboutPage.nodes[0].tagLine");
  const DESCRIPTION_HTML = get(
    data,
    "allContentfulAboutPage.nodes[0].description.childMarkdownRemark.html"
  );

  return (
    <Layout>
      <Helmet title={PAGE_TITLE}>
        <meta name="description" content={PAGE_DESCRIPTION} />
      </Helmet>
      <Container>
        <HeaderImageContainer>
          <HeaderImage src={IMAGE}></HeaderImage>
        </HeaderImageContainer>
        <Body>
          <Heading>{HEADING}</Heading>
          <TagLine>{TAG_LINE}</TagLine>
          <Description
            dangerouslySetInnerHTML={{
              __html: DESCRIPTION_HTML,
            }}></Description>
        </Body>
      </Container>
    </Layout>
  );
};

export default About;

const HeaderImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const HeaderImage = styled.img`
  height: 100%;
  max-width: 900px;
  width: 100%;
  margin: auto;
`;

const Heading = styled(Title)`
  letter-spacing: 1px;
  font-size: 4rem;
  text-align: center;
`;

const TagLine = styled.h2`
  font-size: 2rem;
  display: block;
  width: 100%;
  text-align: center;
`;

const Description = styled.div`
  max-width: 600px;

  & p {
    font-size: 1.6rem;
    line-height: 2.5rem;
    letter-spacing: 0.2px;
  }
`;

export const pageQuery = graphql`
  {
    allContentfulAboutPage {
      nodes {
        email
        heading
        pageTitle
        pageDescription
        tagLine
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
